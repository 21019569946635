import React, { useState, useRef, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import "../../styles/Invoice.css";
import ReactToPrint from "react-to-print";

const UserInvoice = () => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [startdate, setStartdate] = useState("");
  const [starttime, setStarttime] = useState("");
  const [endtime, setEndtime] = useState();
  const [enddate, setEnddate] = useState();
  const [price, setPrice] = useState();
  const [categories, setCategories] = useState();
  const [service, setService] = useState();
  const [checked, setChecked] = useState();
  const [advance, setAdvance] = useState();
  const [bilNo, setBillNo] = useState();
  const componentRef = useRef();

  const handlePrint = () => {
    try {
      window.print();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDownload = () => {};
  const handleSend = () => {};

  return (
    <Layout title={"Dashboard - All Users"}>
      <div className="container-fluid m-3 p-3 main">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9 invoice">
            <h1 className="text-center">USER INVOICE</h1>

            <br />

            <div className="print">
              <ReactToPrint
                trigger={() => (
                  <button onClick={handlePrint} className="btn btn-info">
                    print Invoice
                  </button>
                )}
                content={() => componentRef.current}
              />
              <div>
                <input
                  type="text"
                  name="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="m-4"
                  placeholder="enter customer name"
                />
                <input
                  type="Number"
                  name="contact"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  className="m-4"
                  placeholder="enter contact nomber"
                />
                <input
                  type="text"
                  name="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="m-4"
                  placeholder="enter address"
                />
                <div>
                  <input
                    className="m-3"
                    type="date"
                    value={startdate}
                    name="date"
                    onChange={(e) => setStartdate(e.target.value)}
                    placeholder="Enter date"
                    required
                  />
                  <input
                    type="time"
                    value={starttime}
                    name="starttime"
                    onChange={(e) => setStarttime(e.target.value)}
                    placeholder="Enter Start Time"
                    required
                  />
                  <span className="m-2">to</span>
                  <input
                    className="m-3"
                    type="date"
                    value={enddate}
                    name="date"
                    onChange={(e) => setEnddate(e.target.value)}
                    placeholder="Enter date"
                    required
                  />
                  <input
                    type="time"
                    value={endtime}
                    name="endtime"
                    onChange={(e) => setEndtime(e.target.value)}
                    placeholder="Enter End  Time"
                    required
                  />
                </div>
              </div>
              <div>
                <form className="">
                  {/* <li className="nav-item dropdown-list">
                    <Link
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Select Service
                    </Link>
                    <ul className="dropdown-menu">
                      {categories?.map((c) => (
                        <li onClick={() => setSelected(c.name)} key={c._id}>
                          {c.name}
                        </li>
                      ))}
                    </ul>
                  </li>*/}

                  <input
                    type="text"
                    value={service}
                    name="service"
                    onChange={(e) => setService(e.target.value)}
                    placeholder="Enter Service Name"
                    required
                  />

                  <input
                    className="m-2"
                    type="Number"
                    value={advance}
                    name="price"
                    onChange={(e) => setAdvance(e.target.value)}
                    placeholder="Enter Adavance Amount"
                    required
                  />

                  <input
                    className="m-2"
                    type="Number"
                    value={price}
                    name="price"
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Enter Due Amount"
                    required
                  />

                  <input
                    type="Number"
                    value={bilNo}
                    name="billNo"
                    onChange={(e) => setBillNo(e.target.value)}
                    placeholder="Enter Bill No"
                  />
                </form>
              </div>
              <hr />
              <div ref={componentRef} className="maindiv ">
                <section className="mb-5">
                  <div className="p-3 ">
                    <h6 className="datenow">
                      Date of Bill:- {new Date().toLocaleString() + ""}
                    </h6>
                    <h6>Bill Number :- {bilNo}</h6>
                    <div className="billLogo">
                      <img src="/images/favicon.jpeg" height={"90px"} />
                    </div>
                    <h3 className="text-center">CUSTOMER'S BILL </h3>
                  </div>
                  <hr />
                  <ul>
                    <li className="m-2">
                      Your Name:- <b>{name}</b>
                    </li>
                    <li className="m-2">
                      Your Contact Number:- <b>{contact}</b>
                    </li>
                    <li className="m-2">
                      Venue Address:- <b>{address}</b>
                    </li>
                    <li className="m-2">Date of service start:- {startdate}</li>
                    <li className="m-2">Time of service start:- {starttime}</li>
                    <li className="m-2">Date of service end:- {enddate}</li>
                    <li className="m-2">Time of service end:- {endtime}</li>
                  </ul>
                </section>
                <div className="table p-5">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sirial Number</th>
                        <th>Name of Service</th>
                        <th>Advance amount</th>
                        <th>Due Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{1}</td>
                        <td>{service}</td>
                        <td>{advance}</td>
                        <td>{price}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <>
                  <p className="ps-5">
                    <b>NOTE :- </b>Read all terms and conditions, you are
                    signing contract with us its mean you are fully accepted our
                    terms and conditions.
                  </p>
                </>
                <hr />
                <div className="invicer ps-5">
                  <h6>Contact Number : 6260873059</h6>
                  <h6>Email ID : thebandwala@gmail.com</h6>
                  <h6>Website: thebandwala.com</h6>
                </div>
                <h6 className="note">
                  This bill is computer generated, therefore no need to sign by
                  service provider.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserInvoice;
