import React from "react";
import "../../styles/Bottom.css";
import {
  FaLinkedinIn,
  FaTelegram,
  FaFacebookF,
  FaInstagramSquare,
  FaPinterest,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Enquiry from "../../pages/Enquiry.js";

function Bottom() {
  return (
    <div className="Bottom">
      <div className="container-fluid">
        <div className="row mt-20px mb-20">
          <div className="col-md-4 ">
            <h6 className="  text-center cont">CONTACT US</h6>
            <p>Contact Support Timing from 9 A.M to 5 P.M</p>

            <p className="contact ">
              <a
                href="tel:+916260873059"
                className="text-light"
                style={{ textAlign: "center", textDecoration: "0" }}
              >
                &#128222; : 6260873059
              </a>
            </p>
            <p className="email">
              &#x1F4E7; :
              <a
                href="mailto:thebandwala@gmail.com"
                className="text-light"
                style={{ textAlign: "center", textDecoration: "0" }}
              >
                thebandwala@gmail.com
              </a>
            </p>
            <p>HEAD OFFICE: chota chouraha khandwa road bhikangaon 451331</p>
          </div>
          <div className="col-md-4 pd2 ">
            <h6 className="text-center cont">IMPORTANT LINKS</h6>
            <Link
              to="/about"
              style={{ textAlign: "center", textDecoration: "0" }}
              target="_parent"
            >
              <p className="text-light">About Us</p>
            </Link>
            <Link
              to="/blog"
              style={{ textAlign: "center", textDecoration: "0" }}
              target="_parent"
            >
              <p className="text-light">Blog</p>
            </Link>
            <Link
              to="/policy"
              style={{ textAlign: "center", textDecoration: "0" }}
            >
              <p className="text-light paras1">Privacy Policy</p>
            </Link>
            <Link
              to="/Terms"
              style={{ textAlign: "center", textDecoration: "0" }}
            >
              <p className="text-white">Terms & Condition</p>
            </Link>
            <a
              href="https://wa.me/919589484230?text=Hi..."
              target="_blank"
              style={{ textAlign: "center", textDecoration: "0" }}
            >
              <p className="text-light">Suggestions & Feedback</p>
            </a>
          </div>

          <div className="col-md-4">
            <h6 className="cont">CONNECT TO US</h6>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/thebandwala-com-5760b0226 "
                  target="_blank"
                >
                  <FaLinkedinIn
                    className="social-icon"
                    size="1.2rem"
                    color="white"
                  />
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://www.instagram.com/thebandwala/"
                  target="_blank"
                >
                  <FaInstagramSquare
                    className="social-icon"
                    size="1.2rem"
                    color="white"
                  />
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61553158994580"
                  target="_blank"
                >
                  <FaFacebookF
                    className="social-icon"
                    size="1.2rem"
                    color="white"
                  />
                </a>
              </li>
              <br />
              <li>
                <a href="https://in.pinterest.com/thebandwala/" target="_blank">
                  <FaPinterest
                    className="social-icon"
                    size="1.2rem"
                    color="white"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-6 col-md-6 mb-20 ">
            <p id="tred">All Rights reserved by &#169; THEBANDWALA.COM</p>
          </div>
          <div className="col-lg-6 col-md-6 mb-20">
            <p id="tred">Design & Developed by DN Technology</p>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}
export default Bottom;
