import React from "react";
import Layout from "./../components/Layout/Layout";

const Terms = () => {
  return (
    <Layout title={"Terms & Conditions"}>
      <div className="row contactus ">
      <h3>Before booking, please ensure that you have read all the terms and conditions.</h3>
        <div>
          <h6><b>1.</b> For booking, the customer has to pay a 40% advance payment for the booking.</h6>
          <h6> <b>2.</b> The payment for the booking will not be refunded if it is cancelled after 8 days. If the booking is cancelled within 8 days, there will be minor charges of 5 % of the booking amount. </h6>
          <h6><b>3.</b> The customer will be responsible for providing breakfast, lunch, and dinner. </h6>
          <h6><b>4.</b> NO misbehavior will be accepted, and if it occurs, we will immediately terminate the contract and the customer will have to pay the full amount.</h6>
          <h6><b>5.</b> The city where the head office of Bandwala is located will be the location of any legal action.</h6>
          <h6><b>6.</b> If we detect a delay in time, we will attempt to provide compensation in a timely manner, provided there are no bookings on that day and time.</h6>
          <h6><b>7.</b> If there are unmaintained factors such as vehicle breakdowns or anything that cannot be controlled, we will not be liable for any compensation.</h6>
          <h6><b>8.</b>The remaining payment must be paid immediately upon completion of the service.</h6>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
