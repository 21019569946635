import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Layout/Header";
import Bottom from "../components/Layout/Bottom";

const Policy = () => {
  return (
    <div>
      <Helmet>
        <title>Policy of thebandwala</title>
        <meta
          name="description"
          content="Best music bands for our wedding"
        />
      </Helmet>
      <Header />
      <div className="row policy container-fluid">
        <h1 className="text-center">Thebandwala privacy and policy</h1>
        <div className="col-12">
          <h6>1. Your contact details will only be used by <u>theandwala</u> for contact purposes, and we will keep them safe.</h6>
          <h6>2.<u>Thebandwala</u> refrains from sharing your contact details with third parties.</h6>
          <h6>3. The data related to Bandwala cannot be edited by any user on any platform.</h6>
          <h6>4. No users are allowed to edit any unwanted or vulnerable information on <b>theandwala.com</b>.</h6>
          <h6><b>5. Thebandwala</b> is solely responsible for the content of Bandwala.com.</h6>
          <h6>6. If you click on the link which redirects to a third-party website, it is your responsibility and there is no involvement of <b>thebandwala</b>. </h6>
          <h6>7. Kindly note that we do not offer any promotional ads or anything on other websites. We have only one website, <b>thebandwala.com</b>.</h6>
          <h6>8. Our representative does not ask for any OTP or bank-related data and does not share your bank details with our representative.</h6>
        </div>
      </div>
      <Bottom />
    </div>
  );
};

export default Policy;
