import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Layout/Header";
import Bottom from "../components/Layout/Bottom";

const About = () => {
  return (
    <div title={"About us - Thebandwala"}>
      <Helmet>
        <title>About Thrbandwala</title>
        <meta name="description" content="Best music bands for our wedding" />
      </Helmet>
      <Header />
      <div className=" contactus container-fluid ">
        <h1 className="text-center mt-4">About Us</h1>
        <div>
          <h3>Thebandwala</h3>
          <p>
            We thebandwala have working in this field since 20 years as a
            service provider as music band and dhol etc. Our quality is
            unbeatable, we never compromise to our quality because our quality
            is our BRAND. We always try to provide as possible as best service.
            We continuously watch our outgoing services, so that our customers
            get value for money.
          </p>
        </div>
        <div>
          <h3>Quality Assurance</h3>
          <p>
            Quality mean thebandwala our name is our quality, there are a number
            of factors which make us to quality of compared to other service
            provider. We provide that bands which have been audited and verified
            by our quality team. Our quality team checks what the quality is
            providing by us, and what is deficiency in our service so that time
            to time we will be able to make changes and update to our service
            quality.
          </p>
        </div>
        <div className="row">
          <div className="col-md-10">
            <h3>
              Customers are unaware about what they want and what they are going
              to pay{" "}
            </h3>
            <p>
              We have a wide range of services, which fullfil requirement of all
              classes, wheter they Economic, Middle and VIP. Our target is not
              to get high profit but to make happy to our Customers. We do not
              get extra charge to our customers, like all other online platform
              show over charges and get their profit from customers . Our prices
              and rates are as per our musician quality.{" "}
            </p>
          </div>
          <div className="col-md-2 h-20px">
            <img
              src="/images/thinking.png"
              className="item d-block w-2o h-20"
              alt=""
            />
          </div>
        </div>
        <div>
          <h3>Eco friendlly and no adverse effect on health</h3>
          <p>
            Music band dhol these are no effect on health compared to modern DJs
            which has high base and prooven to heart attack and eare damage. We
            are trying to use natural sound technolgy instruments so that it
            would be eco friendlly and make no adverse effect on human being. We
            here many news that due to DJ man death,these are true, and
            exprienced by us when we stand in front of DJ music box.
          </p>
        </div>
        <div>
          <h2 className="text-center">Our Services</h2>
          <h3>Music Bands</h3>
          <p>
            We serve the best music band service,our service is as per modern
            style and our customers get value for money. We try to provide
            quality service so that no customers get disapoint after service. We
            are open to get sugestion and feedback from our customers so that we
            would able to improve.
          </p>

          <h3>Dholi</h3>
          <p>Dholi is less costly and economic service offer by us.</p>

          <h3>Ghodi</h3>
          <p>
            For marriage procession, without ghodi it is remain fade. We are
            trying to provide well timely and quality service for ghodi
          </p>

          <h3>Decorative Lightings</h3>
          <p>
            For marriage procession, for enlight your baraat procession we
            provide decorative lighting, which is best for night baraat.
          </p>
        </div>
      </div>
      <Bottom />
    </div>
  );
};

export default About;
