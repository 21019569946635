import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/auth";
import { NavLink, Link, Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/Adminheader.css";
import axios from "axios";

import { LiaHandshakeSolid } from "react-icons/lia";

const AdminHeader = () => {
  const [enquiry, setEnquiry] = useState();
  const [auth, setAuth] = useState();

  const handleLogout = () => {
    setAuth({ ...auth, user: null, token: null });
    localStorage.removeItem("auth");
    toast.success("logout successfully");
    Navigate("/");
  };

  return (
    <div className="container-fluid">
      <div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand ">
              <img
                src="/images/favicon.jpeg"
                height="80px"
                style={{ marginLeft: "40px", marginBottom: "5px" }}
              ></img>
              <p style={{ fontSize: "10px", padding: "0px" }}>
                <b>Thebandwala.com</b>
              </p>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink onClick={handleLogout} to="/">
                    Logout
                  </NavLink>
                </li>
                <li>
                  <LiaHandshakeSolid />
                  <span className="justify-content-space"></span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default AdminHeader;
