import React from "react";
import "../../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer container-fluid image">
      <div className="row col-md-12  mb-4 pp1">
        <h4 className="text-center pp1">VISION OF THEBANDWALA</h4>

        <h6>
          Our vision is to provide good quality service to our customers, with
          value for money.
        </h6>
        <p>
          Thebandwala provides wedding BANDS, wedding DHOLI,wedding GHODI and DJ
          to memory events and functions. Every moment of life is important and
          makes them memorable. We provide the best quality service to our
          valuable customers.
        </p>
      </div>
      <div className="row ">
        <h4 className="text-center mb-5 rep">MEET OUR TEAM</h4>
        <div className="col-md-4 text-center ">
          <img src="../images/Arun.jpg" className=" item d-block w-50" alt="" />
          <h4>Arun Chouhan</h4>
          <p>Project Manganer</p>
        </div>
        <div className="col-md-4 text-center">
          <img
            src="../images/sandip.jpg"
            className=" item d-block w-50"
            alt=""
          />
          <h4>Sandip Verma</h4>
          <p>Customer Realationship Manager</p>
        </div>
        <div className="col-md-4 text-center">
          <img
            src="../images/tarun.jpg"
            className=" item d-block w-50 "
            alt=""
          />
          <h4>Tarun Chouhan</h4>
          <p>Team Head</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
