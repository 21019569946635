import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import "../styles/Enquiry.css";
const { Option } = Select;

const Enquiry = () => {
  const [categories, setCategories] = useState([]);
  const [email, setEmail] = useState();
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [date, setDate] = useState("");
  const [budget, setBudget] = useState("");
  const [description, setDescription] = useState();
  const navigate = useNavigate();

  //get all category
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/category/get-category");
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something wwent wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const SubmitEnq = async (e) => {
    e.preventDefault({ passive: true });
    try {
      const res = await axios.post("/api/v1/auth/enquiry", {
        name,
        category,
        address,
        contact,
        email,
        budget,
        date,
        description,
      });

      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        navigate("/Greeting");
      } else {
        toast.error(res.data.message);
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("error in enquiry");
    }
  };

  return (
    <div className="container-fluid  enquiry">
      <div className="row">
        <div className="col-md-5 ">
          <h2 className="text-dark">ENQUIRY FORM</h2>
          <ol>
            <li>
              Your personal information such as email id, mobile number would
              not be share to any third party.
            </li>
            <li>
              You are sharing data like contact number and email Id, its means
              you are agree that our representive can contact to you.
            </li>
            <li>
              Kindly provide valid contact information so that we will be able
              to contact you.
            </li>
          </ol>
        </div>
        <div className="col-md-7 enq1">
          <div className="container-fluid ">
            <form onSubmit={SubmitEnq}>
              <div className=" mx-5  enq2">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Name</span>
                </label>
              </div>
              <div className=" mx-5  enq2">
                <input
                  type="text"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Contact</span>
                </label>
              </div>
              <div className=" mx-5  enq2">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Email</span>
                </label>
              </div>
              <div className=" mx-5  enq2">
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Address</span>
                </label>
              </div>
              <div className=" mx-5  enq2">
                <input
                  type="text"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Budget</span>
                </label>
              </div>
              <div className=" mb-5 ms-5 me-3 enq2">
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name text-center"></span>
                </label>
              </div>

              <Select
                placeholder=" Select a category "
                className="w-75 ms-2"
                onChange={(value) => {
                  setCategory(value);
                }}
              >
                {categories?.map((c) => (
                  <Option
                    key={c._id}
                    value={c._id}
                    style={{ opacity: "1", color: "black" }}
                  >
                    {c.name}
                  </Option>
                ))}
              </Select>

              <div className=" m-5  enq2">
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Description</span>
                </label>
              </div>
              <button type="submit" className="text-light">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
