import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import Bottom from "./Bottom";
import YTvedeo from "../YTvedeo";
import Intro from "./Intro";
import Enquiry from "../../pages/Enquiry";
import { useLocation } from "react-router-dom";
import Slider from "../Slider";

const Layout = ({ children, title, description, keywords, author }) => {
  const location = useLocation();
  console.log(location.pathname);

  return (
    <div className="container-fluid">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Header />
      <main style={{ minHeight: "70px" }}>
        <Toaster />
        {children}
      </main>

      {location.pathname === "/" ? <Intro /> : null}

      {location.pathname === "/" ? <Slider /> : null}
      {location.pathname === "/" ? <Enquiry /> : null}

      {location.pathname === "/" ? <Footer /> : null}

      <Bottom />
    </div>
  );
};

Layout.defaultProps = {
  title: "Thebandwala - Book Best music Bands,Dhol",
  description: "Best service made for you",
  keywords: "music Band,Dhol,Ghodi",
  author: "Thebandwala",
};

export default Layout;
