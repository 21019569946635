import React from "react";
import Header from "../components/Layout/Header";
import Bottom from "../components/Layout/Bottom";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <div>
      <Helmet>
        <title>Best muisc bands:-Thebandwala</title>
        <meta name="description" content="Best music bands for our wedding" />
      </Helmet>
      <Header />
      <div className=" contactus container-fluid ">
        <h1 className="text-center mt-4">BEST WEDDING MUSIC BANDS </h1>

        <h4> WEDDING MUSIC BANDS</h4>
        <p>
          Music bands are very glamerous for wedding and marriage.Everone want
          to bands in his marriage. Any marriage procession is fade without the
          music bands presence.There are a number of quality availble in music
          bands service. thebandwala is famous and most reliable in this field{" "}
        </p>

        <img src="../images/barat.jpg" className="mt-3" />
        <p>
          Music bands budgets are varying day by day and time by time, for
          economic marriage its time size reduced as per number of musicians.
          For special marriage budget is no matters but quality matters, for
          quality music bands there are a number of high quality bands available
          in market, who provide theirs service as per customers need. Some
          people thing that only a DJ will be sufficient for marriage
          procession,but is it really true that only modern Djs are enough for
          marriage ? Well this question is not important because today many
          people thing to dance only but WHAT HAPPEN when you only stands in
          front of DJ ? Can DJ will give marriage procession feelings, No .
          Muisc bands are just suffiecient to make marriage procession presence,
          they themselve fill the joyness and proudness that they are present
          there for you wedding. There are some relative points between Music
          bands and Modern Djs
        </p>
        <h4 className="mt-3">Modern Djs advantage and disadvantages</h4>
        <h5 className="mt-3">PROS:-</h5>
        <ul>
          <li>1. No need of many people as in music bands.</li>
          <li>2. Best for dance.</li>
        </ul>
        <h5 className="mt-3">CONS:-</h5>
        <ul>
          <li>
            1. DJs are Very harmfull for health, because due to high base and
            intensity of DJs sound they may cause to endanger the life.
          </li>
          <li>2. No feel of marriage procession as comapared to music bands</li>
          <li>
            {" "}
            3. Only 2 to 3 people get job compared to 10 to 40 peple get work
            from music bands team.
          </li>
          <li>
            4. No Live performance by musicians as compared to music bands.
          </li>
          <li>
            {" "}
            5. Dj sound are harsh for enjoynment as compared to music bands
            which feel very pleasants to hear.
          </li>
          <li>
            {" "}
            6. No people engaged in listening Djs compared to music band, when
            music bands start a number of people only come to hear theirs music.
          </li>
        </ul>
        <h4 className="mt-3">
          Modernize the music band service by <b>thebandwala</b>
        </h4>
        <p>
          All type of marriage procession music bands present show by many
          years, and VIP and famous persons marriage are spotet by high quality
          bands, which having about 20 to 50 musician group that fill the
          brightness in marriage processions. In selecting music bands service
          quality is most important factor to be set in mind while booking a
          music band service, as cheap music band service offer low quality
          service, and for those who are want best quality have to go for famous
          music bands. Its very hard to compare quality service among many bands
          service provider. <br />
          To overcome this problem one name come in mind{" "}
          <a href="https://thebandwala.com">THEBANDWALA.COM</a> this is the best
          one who is for everyone from low to high budget and provide all type
          of qaulity as per customers need. Customer can opt to select what
          budget and quality they want in theire marriage functions, budget
          starts from 5000 and upto 50000. We have not to go for searching
          quality and prices they provide the best one for theire customers.
          Thebandwala is eager to expands theire bussines in india therfore they
          are providing quality service not only profit but fame and brand name
          "Thebandwala". Requested to everyone if you have used theire service
          kindly show in rating on google so that customers are get true review
          and rating of all service providers.
        </p>
        <h4 className="mt-3">Here are some name of known music bands</h4>
        <ul>
          <li>1. Rajkumart band</li>
          <li>2. shyam brass band</li>
          <li>3. shiv shakti band</li>
          <li>4. maharaja band</li>
          <li>5. darbar band</li>
          <li>6. raajkamal band</li>
          <li>7. Sargam band</li>
          <li>7. New Janata band</li>
        </ul>
        <h3 className="mt-3">
          There are a number of music instruments out of which some are showing
          below.
        </h3>
        <h4 className="mt-3">1.Sexophone</h4>
        <img src="../images/sexophone.jpg" className="mb-3" />
        <br />
        <p>
          This is the instruments which is blowen by mouth, its sound is feel
          too good to hear and when it is recorded feeliong amazing to ears.
          There a few musicians are able to blow this instrument. There are
          about 1 to 3 persons who are act as musician to blow sexophone.
        </p>
        <h4 className="mt-3 mb-3">2.Tuba</h4>
        <img src="../images/tuba.jpg" className="mb-3 " />
        <br />
        <p>
          Tuba is one of the instruments which blowen by mouth, its sound is
          harsh but when blowen by in group it filled the lovable sound. there
          are about 3 to 10 person in Music bands group who blows the tuba.
        </p>
        <h4 className="mt-3">3.Trumpet</h4>
        <img src="../images/trumpet.jpg" />
        <br />
        <p>
          Trumpet is a special musical instrument which is very tough to blow by
          general people. It required very high air pressure to create sounds.
          In modern days musicians are available in low number as this
          instrument required more and more practice to blow.
        </p>
        <h4 className="mt-3">4.Clarinet</h4>
        <img src="../images/clarinet.jpg" />
        <br />
        <p>
          Clarinet is one of the sweet sound musical instrument, which is mix in
          band group musical instrument sound that feel very pleasant. there are
          about 1 to 3 musicians are present in a group of music bands.
        </p>
        <h4 className="mt-3">5.Brass Horne</h4>
        <img src="../images/horne.jpg" />
        <br />
        <p>
          Brass horne is a big instrument, one of the heviest in music band. Its
          sound heavy with high base, and also very tough to blow by general
          people. It required very practice to able to blow it. Without Horne
          music band is tastless.
        </p>
        <h4 className="mt-3 mb-4">6.Drum</h4>
        <img src="../images/drum.jpg" className="mb-3" />
        <br />
        <p>
          Drum is a music instrument which is drummed by hands, its very
          important instrument for music bands. there are about 2 to 5 drummer
          present in a music bands group.
        </p>
        <h4 className="mt-3 mb-4">7.Dhol</h4>
        <img src="../images/dhol.jpg" className="mb-3" />
        <br />
        <p>
          Dhol is a big music instrument and has very high base,its presence is
          to make base very good.
        </p>
        <h5 className="mt-3"> MUSIC BANDS are best compared to modern Djs </h5>
        <p>
          Modern DJs are replacing music bands, but the public is unaware of the
          side effects of DJs. Music bands are a natural sound, and everyone
          wants to hear them with joy and enjoy in theirs functions. A marriage
          procession is tasteless without bands.{" "}
          <a href="https://Thebandwala.com">Thebandwala.com</a> is a startup to
          bring back on track music bands to go ahead with the latest trends and
          technology, so that musicians will be upgraded with possible
          resources.
        </p>
      </div>
      <Bottom />
    </div>
  );
};

export default Blog;
