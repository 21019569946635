import React from "react";
import "../styles/Slider.css";

const Slider = () => {
  return (
    <div className="container-fluid my-3">
      <div
        id="carouselExampleInterval"
        className="carousel slide crs"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner slideryoutube">
          <div className="carousel-item active crss" data-bs-interval={2000}>
            <iframe
              src="https://www.youtube.com/embed/Kj6EpqJ8HqM"
              title="thebandwala serivce"
              allowFullScreen="allowfullscreen"
            />
          </div>
          <div className="carousel-item crss" data-bs-interval={3000}>
            <iframe
              title="thebandwala serivce"
              src="https://www.youtube.com/embed/z6vrN4iHpPE?si=ocEXabl5sIWPFw9Y"
              allowFullScreen="allowfullscreen"
            ></iframe>
          </div>
          <div className="carousel-item crss">
            <iframe
              src="https://www.youtube.com/embed/enj9dijy07I?si=5_qkhcuwDWJlvnWD"
              allowFullScreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
        <button
          className="carousel-control-prev "
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon pp12"
            aria-hidden="true"
          />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next "
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon pp12"
            aria-hidden="true"
          />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Slider;
