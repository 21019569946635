import React, { useEffect, useRef } from "react";
import "../../styles/Intro.css";
import { motion, easeIn, useAnimate, useInView } from "framer-motion";

const Intro = () => {
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope);
  const scrollRef = useRef();

  useEffect(() => {
    if (isInView) {
      animate(
        "div",
        { x: 0 },
        { duration: 1, delay: 1 },
        { Transition: easeIn }
      );
    } else {
      animate("div", { x: -300 });
    }
  }, [isInView]);

  useEffect(() => {
    if (isInView) {
      animate(
        ".p4, .p5",
        { x: 0 },
        { duration: 1, delay: 1 },
        { Transition: easeIn }
      );
    } else {
      animate(".p4, .p5", { x: +300 });
    }
  }, [isInView]);

  return (
    <div className="intro">
      <div className="row ">
        <h4 className="text-center text-white introh4 mb-4 font-weight-bold">
          BOOK THEBANDWALA FOR YOUR SPECIAL EVENTS
        </h4>
        <p className="text-white">
          We provide the trusted service, our service is value for money. Anyone
          can book online, we do not charge any extra amount by our customers.We
          provide quality service as per our rates and never compromise to our
          quality. Search and find best service as per your requirement, we have
          a wide range of rates and quality that will fit your specific need.
        </p>

        <div ref={scope} className="row backsuport ">
          <div className=" col-md-3 me-5 p1">
            <h4 className=" text-center"> Value for Money</h4>
            <p>
              We do not work for profit only, if our customers are not
              satisfied, then we havent going on to complete our vision. We take
              money for to give satisfaction to our valuable customers, for that
              we offer the value in terms or our service, so that we recall in
              future by our customers.
            </p>
          </div>
          <div className=" col-md-3 me-5 p2">
            <h4 className=""> Timelly Service</h4>
            <p>
              We are determinated toward timely service, if time missed
              everything missed. To keep in mind of our customers time,we
              alwayas ahead to provide timely service.
            </p>
          </div>
          <div className="col-md-3 me-5 p3">
            <h4 className=""> No Hidden Charges, No Booking Charges</h4>
            <p>
              Anyone can book our service from anywhere we do not take extra
              charges wheter it is online or offline booking. Our service rates
              changes as per time and quality not as per booking modes whehter
              online or offline. We want to save cistomers valuable time.
            </p>
          </div>
        </div>
        <div ref={scope} className="row backsuport2">
          <div className=" col-md-3  me-5 p4">
            <h4 className="m">Quality as per demand and Rates</h4>
            <p>
              Kindly note that service rates changeable due to seasional
              service, we are humbly request to our customers not considerd our
              past booking rates
            </p>
          </div>
          <div className=" col-md-3 me-5 p5">
            <h4>Charges Varies as per Time and Dates</h4>
            <p>
              As the marriage and wedding are seasional occasions therefore our
              rates are change as per time, but we do not get extra charges we
              take payments as per our services, for primium services rates
              would be as per quality.
            </p>
          </div>
          <div className="col-md-3 p6">
            <h4 className="tb">Trusted Brand Name </h4>
            <p>
              Thebandwala is trusted brand name in field of bands, ghodi and
              lighting services, our aim is to natinalize our brand so that we
              will able to provide best service.
            </p>
          </div>
        </div>
        <h4 className="text-center my-5 text-white">THEBANDWALA SERVICES</h4>
        <h6 className="text-white">
          Thebandwala has specialise in these services, We have best quality and
          wide range of price
        </h6>
        <div className="col-md-6 pd1">
          <h5 className="mb-4 text-white">Wedding Music Band</h5>
          <img
            src="../images/bandgroup.jpg "
            className=" item d-block w-50  text-center"
            alt=""
          />
          <p className="mt-4 text-white">
            Marriage music band is the basic need of any wedding ceremony. There
            are a wide range of music bands service. music Band is the part of
            every marriage function, without band marriage procession feels
            tastless.
          </p>
        </div>
        <div className="col-md-6 pd1 ">
          <h5 className="mb-4 text-white">Dholi Service</h5>
          <img
            src="../images/dholgroup.jpg"
            className=" item d-block w-50"
            alt=""
          />
          <p className="mt-4 text-white">
            Dholi for dance in marriage is become essenctial for modern wedding,
            its presence makes enjoyfull marriage Our starting rates are Rs 500.
            We have a number of dholi drummer.
          </p>
        </div>
      </div>

      <div className="row">
        <div
          initial={{ x: -400 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ root: scrollRef }}
          className="col-md-6 pd1"
        >
          <h5 className="mb-4 text-white">Ghodi For Barat</h5>

          <img
            whileHover={{ scale: 1.1 }}
            src="../images/ghoda.jpg"
            className=" item d-block w-50  "
            alt=""
          />
          <p className="mt-4 text-white">
            For marriage procession we have good quality in ghodi service. Our
            timely service makes our customers more confortable, because time is
            most important.
          </p>
        </div>
        <div
          initial={{ x: +400 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ root: scrollRef }}
          className="col-md-6 pd1"
        >
          <h5 className="mb-4 text-white">Lighting for Baraat</h5>
          <img
            src="../images/Light.jpg"
            className=" item d-block w-50 "
            alt=""
          />
          <p className="mt-4 text-white">
            We provide delightfull lighting system for decorative barat, its
            very good for every barat specialy if its night.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Intro;
