import Layout from '../components/Layout/Layout';
import React from 'react'

const Greeting = () => {

    return (
        <Layout title={"Thnak you for your enquiry - Thebandwala"}>
            <div className=" contactus container-fluid ">

                <h1 className="text-center mt-4">We are happy that you have enquiry our service</h1>


                <div>
                    <h4>
                        Thank you dear USER. Our representative will get in touch as soon as possible,
                        our customers are our proude,we shall try to provide our best for you.
                    </h4>
                    <img src="../images/greeting.jpg" className=" item d-block w-100 " alt="" />

                </div>



            </div>
        </Layout >
    );
}

export default Greeting;
